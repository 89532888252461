import Vue from 'vue'
import VueMeta from 'vue-meta';
import App from './App.vue'
import router from './router'
require(`@/plugins/element-ui`)
require(`@/plugins/clipboard`)
require(`@/plugins/base64`)
require(`@/plugins/axios`)
require(`@/plugins/device`)


import 'element-ui/lib/theme-chalk/index.css'
import '@/icons' // icon

Vue.use(VueMeta);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
