import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SubConverter",
    meta: {
      title: '在线订阅转换工具-Shadowsocks/V2Ray/Trojan/Surge/Clash/Sing-Box/Quantumult/Loon',
      metaTags: [
        {
          name: "description",
          content: "最全面机场节点在线订阅链接转换工具,支持Clash订阅转换,Sing-Box订阅转换,V2Ray订阅转换,Shadowrocket转换,Quantumult X格式,Surge格式,Shadowrocket格式,支持多种分流规则配置,支持生成短链"
        }
      ]
    },
    component: () => import("../views/Subconverter.vue")
  },
  {
    path: "/clash-to-singbox",
    name: "Clash to SingBox",
    meta: {
      title: 'Clash转Sing-Box订阅工具-Clash/Sing-Box/SingBox',
      metaTags: [
        {
          name: "description",
          content: "Clash转Sing-Box: Clash订阅转SingBox订阅格式，支持多个订阅链接合并为单个订阅，支持多种分流规则配置，支持生成短链。SingBox订阅转换、Clash/Sing-Box/SingBox"
        }
      ]
    },
    component: () => import("../views/Clash2SingBox.vue")
  },
  {
    path: "/v2ray-to-singbox",
    name: "V2Ray to SingBox",
    meta: {
      title: 'V2Ray转Sing-Box订阅工具-V2Ray/VMess/Sing-Box/SingBox',
      metaTags: [
        {
          name: "description",
          content: "V2Ray转Sing-Box: V2Ray订阅转SingBox订阅格式，VMess转Sing-Box。支持多个订阅链接合并为单个订阅，支持多种分流规则配置，支持生成短链。SingBox订阅转换、V2Ray/VMess/Sing-Box/SingBox"
        }
      ]
    },
    component: () => import("../views/V2Ray2SingBox.vue")
  },
  {
    path: "/clash-to-v2ray",
    name: "Clash to V2Ray",
    meta: {
      title: 'Clash转V2Ray订阅工具-Clash/V2Ray/VMess',
      metaTags: [
        {
          name: "description",
          content: "Clash转V2Ray: Clash订阅转V2Ray订阅格式，Clash转VMess，支持多个订阅链接合并为单个订阅，支持多种分流规则配置，支持生成短链。V2Ray订阅转换、Clash/V2Ray/VMess/VLESS"
        }
      ]
    },
    component: () => import("../views/Clash2V2Ray.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to, from) => {
  gtag('config', 'G-N81WHDN3WS', {'page_path': to.path});

  // This updates the page title
  document.title = to.meta.title || 'Default Page Title';

  // Remove any stale meta tags
  const metaTags = document.querySelectorAll('meta[name="description"], meta[property^="og:"]');
  metaTags.forEach(tag => tag.parentNode.removeChild(tag));

  // Add the meta tags defined in the current route's meta field
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach(tagDef => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
      document.head.appendChild(tag);
    });
  }
});

export default router;
